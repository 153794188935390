import { App, Form, Input, Modal, Select } from "antd";
import { gql, useMutation } from "urql";

import LocationPicker from "@/components/LocationPicker";
import formatAddress from "@/functions/format-address";
import useRelationProfile from "@/hooks/use-relation-profile";

import RelatedProductsSelector from "./RelatedProductsSelector";

interface RegisterIssueModalValues {
  locationId?: string;
  relatedAppointmentId?: string;
  assignedEmployeeId?: string;
  subject?: string;
  comment?: string;
}

interface RegisterIssueModalProps {
  onClose: () => void;
  initialValues?: RegisterIssueModalValues;
}

export default function RegisterIssueModal({ onClose, initialValues }: RegisterIssueModalProps) {
  const { notification } = App.useApp();
  const [formInstance] = Form.useForm();

  const relationId = Form.useWatch("relationId", formInstance);
  const locationId = Form.useWatch("locationId", formInstance);
  const [{ fetching: loading }, registerIssueAsync] = useMutation(RegisterIssueMutation);
  const { isDealer, locationMap, relationList, relation } = useRelationProfile();

  const handleOnSubmit = async ({ relationId, ...values }: Record<string, unknown>) => {
    try {
      await registerIssueAsync({ ...values, relationId: relationId ?? relation.id });
      onClose();

      notification.success({ message: "Melding is toegevoegd" });
    } catch {
      notification.error({ message: "Er ging iets mis bij het registeren van de melding. Neem contact op via service@defibrion.nl" });
    }
  };

  return (
    <Modal confirmLoading={loading} onCancel={onClose} onOk={() => formInstance.submit()} title="Melding registeren" open>
      <Form form={formInstance} onFinish={handleOnSubmit} layout="vertical" initialValues={initialValues}>
        {isDealer && (
          <Form.Item name="relationId" label="Klant" rules={[{ required: true }]}>
            <Select
              options={relationList.map(option => ({
                label: `(${option.afasCode}) ${option.name}`,
                value: option.id,
              }))}
            />
          </Form.Item>
        )}
        <Form.Item name="locationId" label="Locatie" rules={[{ required: true }]}>
          {isDealer ? (
            <Select
              options={
                relationId !== undefined
                  ? locationMap[relationId].locations.map(option => ({
                      label: (
                        <div>
                          <div>{option.name}</div>
                          <small>{formatAddress(option.address)}</small>
                        </div>
                      ),
                      value: option.id,
                    }))
                  : []
              }
            />
          ) : (
            <LocationPicker includeDeleted={false} />
          )}
        </Form.Item>
        <Form.Item name="relatedProducts" label="Gerelateerde producten">
          {undefined !== locationId ? <RelatedProductsSelector multiple locationId={locationId} /> : <Input disabled />}
        </Form.Item>
        <Form.Item name="subject" label="Titel" rules={[{ required: true }]}>
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item name="comment" label="Omschrijving" rules={[{ required: true }]}>
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

const RegisterIssueMutation = gql`
  mutation ($relationId: ID!, $locationId: ID!, $relatedProducts: [ID!], $subject: String!, $comment: String!) {
    raiseIssue(
      input: { relationId: $relationId, locationId: $locationId, relatedProductIds: $relatedProducts, subject: $subject, comment: $comment }
    ) {
      issue {
        id
      }
    }
  }
`;
