import { MailFilled, MailOutlined, PhoneFilled, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { App, Form, Input, Modal } from "antd";
import * as React from "react";
import { gql, useMutation } from "urql";

import LocalePicker from "@/components/LocalePicker";
import formatAddress from "@/functions/format-address";

interface UpdateLocationModalProps {
  onClose: () => void;
  location: Record<string, string>;
  relationId: string;
}

export default function UpdateLocationModal({ onClose, location, relationId }: UpdateLocationModalProps) {
  const { notification } = App.useApp();
  const [formInstance] = Form.useForm();
  const [{ fetching: isUpdateLoading }, updateLocationAsync] = useMutation(UpdateLocationMutation);

  const handleOnSubmit = async (values: Record<string, string>) => {
    try {
      await updateLocationAsync({ ...values, relationId, locationId: location.id });
      notification.success({ message: "Contactgegevens zijn gewijzigd!" });

      onClose();
    } catch {
      notification.error({ message: "Er ging iets mis tijdens het aanpassen van uw contactgegevens" });
    }
  };

  return (
    <Modal
      centered
      confirmLoading={isUpdateLoading}
      onOk={() => formInstance.submit()}
      onCancel={onClose}
      okText="Wijzigingen opslaan"
      title="Contactgegevens aanpassen"
      open
    >
      <span style={{ fontStyle: "italic", fontSize: 12 }}>Velden met * dienen gevuld te zijn</span>
      <Form form={formInstance} layout="vertical" initialValues={{ ...location }} onFinish={handleOnSubmit} style={{ paddingBlock: 12 }}>
        <Form.Item label="Contactpersoon" name="contactPerson" required>
          <Input prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item label="Adres" extra="Adreswijzigingen graag doorgeven via service@defibrion.nl">
          <Input disabled value={formatAddress(location.address)} />
        </Form.Item>
        <Form.Item label="Primair e-mail adres" name="primaryEmail" required>
          <Input prefix={<MailOutlined />} type="email" />
        </Form.Item>
        <Form.Item label="Secondair e-mail adres" name="secondaryEmail">
          <Input prefix={<MailFilled />} type="email" />
        </Form.Item>
        <Form.Item label="Telefoonnummer" name="phoneNumber">
          <Input prefix={<PhoneOutlined />} />
        </Form.Item>
        <Form.Item label="Mobiel telefoonnummer" name="mobilePhoneNumber">
          <Input prefix={<PhoneFilled />} />
        </Form.Item>
        <Form.Item label="Taalinstelling" name="locale" required>
          <LocalePicker />
        </Form.Item>
      </Form>
    </Modal>
  );
}

const UpdateLocationMutation = gql`
  mutation (
    $relationId: ID!
    $locationId: ID!
    $contactPerson: String
    $primaryEmail: String
    $secondaryEmail: String
    $phoneNumber: String
    $mobilePhoneNumber: String
    $locale: String
  ) {
    updateLocation(
      input: {
        relationId: $relationId
        locationId: $locationId
        contactPerson: $contactPerson
        primaryEmail: $primaryEmail
        secondaryEmail: $secondaryEmail
        phoneNumber: $phoneNumber
        mobilePhoneNumber: $mobilePhoneNumber
        locale: $locale
      }
    ) {
      location {
        id
        contactPerson
        primaryEmail
        secondaryEmail
        phoneNumber
        mobilePhoneNumber
        locale
      }
    }
  }
`;
